<template>
  <div>
    <v-container class="grid-list">
      <h2>
        {{ $t('Assignment examples') }}
      </h2>
      <v-card
        name="fill in the blanks"
        elevation="3"
        tile
        dense
        width="700"
      >
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-2">
              Fill in the blanks (2-3 lessons)
            </h3>
          </div>
        </v-card-title>
        <v-card-text class="ma-0">
          <div class="subtitle-1">
            Fill in the blanks is a quick way of getting into DRAMA STUDIO, and
            initiating good dialogue on theater, communication and the tools of
            drama
          </div>
          <div class="subtitle-1">
            Fill in the blanks is about having a pre-made stage play where
            elements are missing. Imagine a scene from Romeo and Juliet, where
            all Romeos lines are blank. Depending on what you want to teach, you
            can create stage plays with different elements missing. For instance
            you could leave out some of the manuscript, direction, scenography
            or lighting
          </div>
        </v-card-text>
        <v-row>
          <v-spacer />
          <v-card-actions class="ma-0">
            <v-btn
              flat
              color="primary"
              @click="openfillAssimnent"
            >
              {{ $t('See Example') }}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
      <v-card
        name="pres tool"
        elevation="3"
        tile
        dense
        width="700"
      >
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-2">
              DRAMA STUDIO as a presentation tool (2-3 lessons)
            </h3>
          </div>
        </v-card-title>
        <v-card-text class="ma-0">
          <div class="subtitle-1">
            This assignment is meant as presentation training and as inspiration
            for using DRAMA STUDIO as a presentation tool
          </div>
        </v-card-text>
        <v-row>
          <v-spacer />
          <v-card-actions class="ma-0">
            <v-btn
              flat
              color="primary"
              @click="openpresAssimnent"
            >
              {{ $t('See Example') }}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Assimnents',
    props: {},
    data () {
      return {}
    },
    created () {},
    methods: {
      openfillAssimnent () {
        window.open(
          'https://teacher.dramastudio.net/pdfs/Fill_in_the_blanks.pdf',
          '_blank',
        )
      },
      openpresAssimnent () {
        window.open(
          'https://teacher.dramastudio.net/pdfs/DRAMA_STUDIO_as_a_presentation_tool.pdf',
          '_blank',
        )
      },
    },
  }
</script>

<style lang="scss" scoped></style>
